<template>
  <div class="my-sales-record-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">添加报名信息</span>
      </div>
      <div class="content">
        <el-tabs v-model="activeTab" tab-position="left">
          <el-tab-pane label="选择证书商品" name="1">
            <div class="title">
              <span>选择证书商品</span>
              <el-button type="primary" @click="nextStep">下一步</el-button>
            </div>
            <div class="tab-main">
              <table-list
                :loading="loading"
                :data="dataList"
                :search-form="searchForm(this)"
                :columns="columns(this)"
                :options="{ singleCurrent: true }"
                :pager="pager"
                @search="onSearch"
                @singleSelectChange="handleSelectionChange"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              >
              </table-list>
            </div>
          </el-tab-pane>
          <el-tab-pane label="添加购买用户" name="2" :disabled="!currentRow.productId">
            <div class="title">
              <span>添加购买用户信息</span>
              <el-button @click="backPrev">上一步</el-button>
              <el-button type="primary" :loading="importLoading" @click="add">确定添加</el-button>
            </div>
            <div class="tab-main">
              <el-upload
                class="upload-batch"
                drag
                action=""
                :auto-upload="false"
                :file-list="fileList"
                :on-remove="handleFileRemove"
                :on-change="handleChange"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">点击批量上传</div>
                <div slot="tip" class="el-upload__tip">
                  <p>文件大小5MB，仅支持excel</p>
                  <div class="btns">
                    <el-button @click="downloadTemplate">下载导入模板</el-button>
                  </div>
                </div>
              </el-upload>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'productName',
    label: '证书商品名称',
    minWidth: 146,
  },
  {
    prop: 'skuName',
    label: '服务名称',
    minWidth: 174,
  },
  {
    prop: 'price',
    label: '价格',
    minWidth: 108,
    align: 'right',
    formatter: row => {
      return '￥' + row.price
    },
  },
  {
    minWidth: 50,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '证书商品名称',
    prop: 'productName',
  },
  {
    label: '服务名称',
    prop: 'skuName',
  },
]
import {
  offlineSalesRecordProductSku,
  offlineSalesRecordImport,
  offlineSalesRecordDownload,
} from '@/api/center'
import to from 'await-to'
import TableList from '@/components/TableList'
export default {
  name: 'MySalesRecordAdd',
  components: { TableList },
  data() {
    return {
      activeTab: '1',
      loading: false,
      importLoading: false,
      columns,
      searchForm,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      dataList: [],
      currentRow: {},
      fileList: [],
    }
  },
  watch: {
    fileList: {
      handler(val) {
        if (val.length) {
          document.querySelector('.el-upload').style.display = 'none'
          document.querySelector('.el-upload__tip').style.display = 'none'
        } else {
          document.querySelector('.el-upload').style.display = 'inline-block'
          document.querySelector('.el-upload__tip').style.display = 'block'
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        offlineSalesRecordProductSku({
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    // 单选
    handleSelectionChange(val) {
      this.currentRow = val
    },
    async downloadTemplate() {
      const [res, err] = await to(offlineSalesRecordDownload())
      if (err) return this.$message.warning(err.msg)
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE10
        navigator.msSaveBlob(blob, this.filename)
      } else {
        const href = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href
        a.download = '导入模板'
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    handleChange(file, fileList) {
      if (
        file.raw.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        file.raw.type != 'application/vnd.ms-excel'
      ) {
        this.fileList = []
        return this.$message.warning('上传的文件格式不为excel文件')
      }
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },
    handleFileRemove(file, fileList) {
      this.fileList = fileList
    },
    async importSubmit() {
      if (this.importLoading) return
      if (!this.fileList.length) return this.$message.warning('请先选择文件')
      let formData = new FormData()
      formData.append('productId', this.currentRow.productId)
      formData.append('price', this.currentRow.price)
      formData.append('productId', this.currentRow.productId)
      formData.append('productName', this.currentRow.productName)
      formData.append('skuCode', this.currentRow.skuCode)
      formData.append('skuName', this.currentRow.skuName)
      formData.append('file', this.fileList[0].raw)
      this.importLoading = true
      const [, err] = await to(offlineSalesRecordImport(formData))
      this.importLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push('/scenter/mySalesRecord?active=2')
    },
    backPrev() {
      this.activeTab = '1'
    },
    nextStep() {
      console.log(this.currentRow)
      if (this.currentRow.productId) {
        this.activeTab = '2'
      } else {
        this.$message.warning('请先选择证书商品')
      }
    },
    add() {
      this.importSubmit()
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getList()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
.my-sales-record-add {
  ::v-deep.el-tabs {
    .el-tabs__item {
      width: 180px;
      height: 60px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 60px;
      color: #c9c9c9;
      text-align: center;
      border-right: 2px solid #f5f5f5;
      &.is-active {
        color: #ff7b33;
        background: #fcfbfa;
      }
    }
  }
  ::v-deep.el-card {
    .el-card__body {
      padding: 0;
    }
    .content {
      height: calc(100vh - 210px);
      .title {
        height: 78px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 18px;
        font-weight: 500;
        line-height: 78px;
        color: #4d4e5d;
        padding: 0 20px;
        display: flex;
        align-items: center;
        span {
          margin-right: auto;
        }
      }
    }
  }
  ::v-deep.el-tabs {
    height: 100%;
    .el-tabs__header {
      height: 100%;
      padding: 20px;
      border-right: 1px solid #e2e2e2;
      margin-right: 0;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .tab-main {
      height: calc(100vh - 290px);
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  ::v-deep.table-search-bar {
    padding: 0;
    .form {
      width: 100%;
      display: flex;
      .btns {
        margin-left: auto;
        .el-form-item {
          margin-right: 0;
        }
        .el-button {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  #pane-2 {
    ::v-deep.el-upload {
      padding-top: 15vh;
    }
    .upload-batch {
      position: relative;
      text-align: center;
    }
    .el-upload__tip {
      p {
        margin-bottom: 40px;
      }
    }
    ::v-deep.el-upload-list {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: left;
      .el-upload-list__item-name {
        display: inline;
      }
      .el-icon-close {
        position: initial;
      }
    }
  }
  ::v-deep.el-upload {
    .el-upload-dragger {
      width: 120px;
      height: 120px;
      background: #fafafa;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .el-upload__text {
        color: #909399;
      }
      .el-icon-upload {
        margin: 0px;
        font-size: 36px;
      }
    }
  }
}
</style>
